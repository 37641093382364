import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`A story that is commonly told about Orson Welles is that he watched John Ford’s Stagecoach (1939) some forty times after coming to Hollywood to make his first feature film. Although we were unable to determine how many times Stagecoach was screened for Welles, a surviving document compiled by Welles’ assistants reveals that he also watched 68 other films during pre-production for “The Heart of Darkness.” In addition to studying these films as a sort of crash course introduction to Hollywood film production, Welles also intended to insert short excerpts from several existing films directly into his own production and to use others as rear projections for “process shots” in order to avoid location shooting and decrease the budget. Ironically, these films are the only surviving evidence of how parts of Welles’s “The Heart of Darkness,” however fleeting or in the background, would actually have looked.`}</MDXTag>
<MDXTag name="p" components={components}>{`During a production budget meeting on December 14, 1939, RKO’s production team proposed purchasing “stock shots” to “save cost of expensive mat`}{`[`}{`te]s in scenes from boat in which we see the coast of the dark country.” Use of stock footage was (and is) common practice, and a document labeled “Movie Notes” notes the price per foot budgeted for duplicating and licensing existing footage and, in some cases, identifies the specific excerpts Welles wanted to use in his film. These films included ethnographic films like Chang (1927), Congorilla (1932), and Baboona (1935) as well as fiction films like Sanders of the River (1935) and The Four Feathers (1939). Welles planned to use shots of lush jungles, rushing rivers, exotic animals, and African native peoples.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/baboona.png","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/congorilla.png","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/sanders-1.png","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/sanders-2.png","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`In an undated outline that appears to have been written by Orson Welles, the river along which Marlow and his crew travel is described as “the single most important aspect of the story.” It is unnamed and intentionally vaguely located geographically, described only as “snake-shaped.” The river was to have structured the story of their journey into “The Heart of Darkness,” which was divided into six segments that are characterized by progressively greater levels of decay and descent into thematic darkness. In the earliest sections, the river is “broad” and the surrounding jungle is “biggest and healthiest.” But, as they travel the river, it narrows and the animal life grows more menacing. From these indications, we can surmise that Welles’s choices of excerpts from existing films would have been keyed to these segments as described in the script. Shots of baboons, giraffes, elephants, and wildebeests from Congorilla and Baboona would likely have been part of the first segment of the journey, when the river is teeming with life, while shots of crocodiles from one or both of the same two films would likely have been part of the second segment of the journey in which the vegetation recedes and animal life becomes reptilian.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Research on the films Welles watched was conducted by:`}</MDXTag>
<MDXTag name="p" components={components}>{`Rachel Byrum, Alexandra Niforos, and Jordan Stanton.`}</MDXTag>
<MDXTag name="h2" components={components}>{`All references to the films Welles watched come from:`}</MDXTag>
<MDXTag name="p" components={components}>{`Miriam Geiger and Richard Baer, “General Research on ’The Heart of Darkness.’” Beatrice Welles Collection, University of Michigan Special Collections Research Center.`}</MDXTag>
<MDXTag name="p" components={components}>{`Welles mss. Manuscripts department, Lilly Library, Indiana University, Bloomington, Indiana.`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Films Welles Watched","key":"database-of-films-welles-watched","parentKey":"heart-of-darkness","sortOrder":3};

  